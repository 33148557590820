<template>
	<div class="con_box">
		<div :style="{ display: 'flex' }" ref="floorImage">
			<div>
				<div class="col-12 EHPLeft">
					<div class="d-flex" :style="{ padding: '10px' }">
						<div class="row col-8">
							<div class="col-lg-2 SearchLabel">
								<span>{{$t('구역')}}</span>
							</div>
							<div class="col-lg-4">
								<tree-select
									class="treeText"
									:load-options="loadOptions"
									:placeholder="controlMessages.ZONE_TREE_PLACEHOLDER_MESSAGE"
									:noChildrenText="controlMessages.ZONE_TREE_NOCHILDREN_MESSAGE"
									:noResultsText="$t(controlMessages.COMMON_TREE_NOSEARCH_MESSAGE)"
									v-model="zoneIdx"
									:multiple="false"
									:options="zoneIdxList"
									:clear-on-select="false"
									:max-height="200"
								/>
							</div>
						</div>
						<div class="col-4 font-weight-bold d-flex flex-row justify-content-end align-items-center" :style="{ fontSize: '15px' }">
							<div class="p-l-5 p-r-5">
								<div class="btn-group">
									<button ref="showDetail" class="btn btn-white" :class="'showDetailButton active'" @click="detailHideShow('show')">{{$t('상세보기')}}</button>
									<button ref="hideDetail" class="btn btn-white" :class="'hideDetailButton'" @click="detailHideShow('hide')">{{$t('간단보기')}}</button>
								</div>
							</div>
						</div>

						<!-- <div class="col-lg-2 SearchLabel">
              <span>기본제어</span>
            </div>
            <div class="col-lg-4">
              <b-form-select v-model="ctrlCode" :options="equipCtrlList" disabled></b-form-select>
            </div> -->
					</div>

					<div :id="'panel'" class="tabcontent controlPanel" :class="'current'" :data-panel="'panel'">
						<div class="con_wrap" :style="{ height: '100%' }">
							<!-- EHP list -->
							<!-- 		:equipList="zoneInfoList[zoneIdx - 1].zoneName === '전체' ? equipList : equipList.map((v) => v.zoneName === zoneInfoList[zoneIdx - 1].zoneName)" -->
							<ehp-accordian
								:ref="'accordian'"
								v-if="equipList && equipListDetail"
								:zoneInfo="zoneInfoList[zoneIdx - 1]"
								:equipList="equipList"
								:equipListDetail="equipListDetail"
								:ctrPtAddr="ctrPtAddr"
								:ctrlPropList="ctrlPropList"
								:update="equipStatus"
							/>
							<!-- floor plan -->
						</div>
					</div>
				</div>
			</div>
			<!-- <div :style="{ width: '1150px', marginLeft: '5px' }">
        <div class="col-12 EHPRight">
          <ehp-floor-img
            ref="floorImage"
            v-if="zoneDsnFilePath"
            :zoneDsnFilePath="zoneDsnFilePath"
            :top="equipLctn.top"
            :left="equipLctn.left"
            :text="''"
          />
        </div>
      </div> -->
		</div>
		<!-- <div class="tab_area">
      <ul class="tab">
        <li v-for="(zoneInfo, index) in zoneInfoList" :class="index == 0 ? 'current' : ''" :key="index" :data-panel="'panel' + index" @click="tabClick($event.target, zoneInfo.zoneIdx, index)">
          {{ zoneInfo.zoneName }}
        </li>
      </ul>
      <div class="btn_area">
        <a id="btn_pre">
          <i class="material-icons" @click="arrowClick($event.target, -1)">arrow_right</i>
        </a>
        <a id="btn_next">
          <i class="material-icons" @click="arrowClick($event.target, 1)">arrow_right</i>
        </a>
      </div>
    </div> -->
	</div>
</template>
<style lang="scss">
	.con_box {
		.EHPLeft {
			background-color: #ffffff;
			border: 1px solid #313131;
			border-radius: 5px;
			min-width: 1638px;
			.vue-treeselect__single-value {
				padding-left: 10px;
			}
			.SearchLabel {
				font-size: 14px;
				font-weight: bold;
				align-self: center;
				text-align: center;
				padding: 0;
			}
		}
		.EHPRight {
			background-color: #ffffff;
			border: 1px solid #313131;
			border-radius: 5px;
			padding: 15px;
			min-width: 830px;
		}
	}
</style>

<script>
	import backEndApi from "@api/backEndApi.js";
	import ehpAccordian from "./EhpAccordian.vue";
	// import ehpFloorImg from "./EhpFloorImg.vue";
	import config from "@libs/config.js";
	import AppErrorHandler from "@src/appErrorHandler";
	import * as popupMessages from "@src/consts/popupMessageConsts";
	import * as controlMessages from "@src/consts/controlMessageConsts";

	export default {
		components: {
			ehpAccordian,
			// ehpFloorImg,
		},
		props: ["zoneInfoList"],
		data() {
			return {
				zoneIdx: null,
				ctrlCode: "CTRL1",
				equipList: null,
				equipListDetail: null,
				zoneDsnFilePath: null,
				ctrPtAddr: null,
				ctrlPropList: null,
				zoneData: {
					zoneIdx: null,
				},
				tabIndex: 0,
				equipCtrlList: null,
				zoneIdxList: null,
				xemsHost: config.backend.apiUrl,
				equipLctn: {
					top: null,
					left: null,
				},
				popupMessages,
				controlMessages,
				statusInterval: null,
			};
		},
		computed: {
			// getReceiveEquipCtrl() {
			// 	return this.$store.getters.getReceiveEquipCtrl;
			// },
		},
		watch: {
			zoneIdx() {
				this.reset();
				this.equipStatus();
			},
			getReceiveEquipCtrl() {
				// console.log("get recivedddd ::::::::");
				// if (this.$store.getters.getReceiveEquipCtrl === true) {
				// 	this.equipStatus();
				// 	this.reloadInterval();
				// 	this.$store.commit("SET_RECEIVE_EQUIP_CTRL", false);
				// }
			},
		},
		async created() {
			this.zoneIdxList = await this.getZoneListHierarchi();
			this.equipCtrlList = await this.getEquipCtrlList();
		},
		mounted() {
			this.init();
			this.equipStatus();

			// this.statusInterval = setInterval(() => {
			// 	this.equipStatus();
			// }, 10000);
		},
		destroyed() {
			clearInterval(this.statusInterval);
		},
		methods: {
			// reloadInterval() {
			// 	console.log("reloaded :::");
			// 	clearInterval(this.statusInterval);

			// 	this.statusInterval = setInterval(() => {
			// 		this.equipStatus();
			// 	}, 10000);
			// },

			async getZoneListHierarchi() {
				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*
					↓↓↓↓↓
				*/
				try {
					let result = await backEndApi.zoneInfo.getZoneListHierarchi();
					let options = [];
					let temp = [];
					temp.push(result.data);
					options = this.setZoneTreeOption(temp);
					return options;
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
				}
			},
			detailHideShow(name) {
				let showButton = document.getElementsByClassName("showDetailButton")[0];
				let hideButton = document.getElementsByClassName("hideDetailButton")[0];

				// this.getEquipListDetail(equip.equipIdx);
				// var listControl;
				var heightList = document.getElementsByClassName(`list_control`);

				if (name === "show") {
					if (!showButton.classList.contains("active")) {
						heightList.forEach((listControl) => {
							listControl.classList.remove("hide");
							listControl.classList.add("open");
							listControl.hidden = false;
						});

						showButton.classList.add("active");
						hideButton.classList.remove("active");
					}
				} else {
					if (!hideButton.classList.contains("active")) {
						heightList.forEach((listControl) => {
							listControl.classList.remove("open");
							listControl.classList.add("hide");
							listControl.hidden = false;
						});

						hideButton.classList.add("active");
						showButton.classList.remove("active");
					}
				}

				// if (event.target.tagName === "IMG") {
				// 	listControl = event.target.parentElement.parentElement.parentElement.querySelector(".list_control");
				// } else {
				// 	listControl = event.target.parentElement.parentElement.querySelector(".list_control");
				// }
			},
			setZoneTreeOption(resultData) {
				resultData.forEach((data) => {
					data.id = data.zoneIdx;
					data.label = data.zoneName;

					if (data.children.length === 0) {
						delete data.children;
					}

					if (data["children"]) {
						return this.setZoneTreeOption(data["children"]);
					} else {
						return data;
					}
				});

				return resultData;
			},

			loadOptions({ callback }) {
				callback();
			},
			init() {
				this.zoneIdx = this.zoneInfoList[0].zoneIdx;
			},
			reset() {
				this.equipList = null;
				this.zoneDsnFilePath = null;
			},
			async getEquipCtrlList() {
				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*
					↓↓↓↓↓
				*/

				try {
					let result = await backEndApi.equipCtrl.getEquipCtrlList("EHPI");

					let options = [];
					this.ctrlCode = result.data[0].ctrlCode;

					for (let i = 0; i < result.data.length; i++) {
						let option = {
							value: result.data[i].ctrlCode,
							text: result.data[i].equipCtrlName,
							data: result.data[i],
						};

						options.push(option);
					}

					return options;
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
				}
			},
			async equipStatus() {
				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*
					↓↓↓↓↓
				*/

				try {
					let result = await backEndApi.equip.equipStatus("EHPI", this.zoneIdx , this.ctrlCode);

					let data = result.data;

					// 구역에 맞는 설비 목록
					this.ctrPtAddr = [];
					this.equipList = data.equipList;
					this.ctrlPropList = data.ctrlPropList;

					// 첫 설비 도면 이미지 경로
					for (let i = 0; i < data.equipList.length; i++) {
						this.ctrPtAddr.push(data.equipList[i].ctrPtAddr);
						let equip = data.equipList[i];
						let equipLctn = equip.equipLctn;

						equip.equipLctn = this.iniEquipLctn(equipLctn, i);
					}

					this.equipListDetail = data.equipList[0].propList;
					this.equipLctn.top = JSON.parse(data.equipList[0].equipLctn).top;
					this.equipLctn.left = JSON.parse(data.equipList[0].equipLctn).left;
					this.zoneDsnFilePath = data.equipList[0].zoneDsnFilePath.replace(/\\/g, "/");
					console.log(this.equipList);
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_SEARCH_NO_DATA_POPUP_MESSAGE, this.alertNoti).errHandling();
				}
			},
			iniEquipLctn(equipLctn, index) {
				let file = new Image();

				file.src = `${this.xemsHost}${this.zoneDsnFilePath}`;

				let xValue;
				file.onload = () => {
					xValue = 1095 / file.width;
				};

				if (xValue === undefined) {
					xValue = 1;
				}

				// TODO
				// DB에서 equipLctn 넣어서 오게되면 삭제
				// DB에 '{"top":0,"left":0}' 이 형태로 저장

				if (equipLctn.trim() === "") {
					return JSON.stringify({ top: index * 50, left: index * 100 });
				} else {
					let splitLctn = equipLctn.split(",");
					let x = splitLctn[0];
					let y = splitLctn[1];

					if (x.trim() === "") {
						x = index * 50;
					} else {
						x = Number(splitLctn[0]) * xValue;
					}

					if (y.trim() === "") {
						y = index * 100;
					} else {
						y = Number(splitLctn[1]) * xValue;
					}

					return JSON.stringify({ top: x, left: y });
				}

				// let x = equipLctn.split(',')[0];
				// let y = equipLctn.split(',')[1];
			},
			async getEquipListDetail(equipIdx) {
				// equipStatus 호출 시 각 설비에 맞는 propList가 존재 하나
				// propList 저장 시 변경 된 prop정보만 가져오기 위해서 한번 더 호출하는 작업 진행
				// 추후 각 설비에 상세조회 api 추가 시 변경 하면 될것으로 보임

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*
					↓↓↓↓↓
				*/

				try {
					let result = await backEndApi.equip.equipStatus("EHPI", this.zoneIdx, this.ctrlCode);

					console.log(result.data);
					this.ctrPtAddr = [];
					this.ctrlPropList = result.data.ctrlPropList;
					let equipList = result.data.equipList;
					for (let i = 0; i < equipList.length; i++) {
						this.ctrPtAddr.push(equipList[i].ctrPtAddr);
					}
					// console.log(this.ctrPtAddr);
					for (let i = 0; i < equipList.length; i++) {
						let equip = equipList[i];
						let equipLctn = equip.equipLctn;
						equip.equipLctn = this.iniEquipLctn(equipLctn, i);
						if (equip.equipIdx === equipIdx) {
							this.equipListDetail = equip.propList;
							this.zoneDsnFilePath = equip.zoneDsnFilePath;

							this.equipLctn.top = JSON.parse(equip.equipLctn).top;
							this.equipLctn.left = JSON.parse(equip.equipLctn).left;
							break;
						}
					}
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
				}
			},

			async ALLgetEquipListDetail(equipIdx) {
				// equipStatus 호출 시 각 설비에 맞는 propList가 존재 하나
				// propList 저장 시 변경 된 prop정보만 가져오기 위해서 한번 더 호출하는 작업 진행
				// 추후 각 설비에 상세조회 api 추가 시 변경 하면 될것으로 보임

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*
					↓↓↓↓↓
				*/

				try {
					let result = await backEndApi.equip.equipStatus("EHPI", this.zoneIdx, this.ctrlCode);
					// console.log(result.data);
					this.ctrPtAddr = [];
					this.ctrlPropList = result.data.ctrlPropList;
					let equipList = result.data.equipList;
					for (let i = 0; i < equipList.length; i++) {
						this.ctrPtAddr.push(equipList[i].ctrPtAddr);
					}
					// console.log(this.ctrPtAddr);
					for (let i = 0; i < equipList.length; i++) {
						let equip = equipList[i];
						let equipLctn = equip.equipLctn;
						equip.equipLctn = this.iniEquipLctn(equipLctn, i);
						console.log(equipIdx);
						if (equip.equipIdx === equipIdx) {
							this.equipListDetail = equip.propList;
							this.zoneDsnFilePath = equip.zoneDsnFilePath;

							this.equipLctn.top = JSON.parse(equip.equipLctn).top;
							this.equipLctn.left = JSON.parse(equip.equipLctn).left;
							break;
						}
					}
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
				}
			},

			setArrowColor(target) {
				let btnPre = target.parentElement.parentElement.querySelector("#btn_pre").children[0];
				let btnNext = target.parentElement.parentElement.querySelector("#btn_next").children[0];

				// 화살표 색깔 설정
				if (this.zoneInfoList.length - 1 == this.tabIndex) {
					btnPre.style.color = "#2d353c";
					btnNext.style.color = "#CCC";
				} else if (this.tabIndex == 0) {
					btnPre.style.color = "#CCC";
					btnNext.style.color = "#2d353c";
				} else {
					btnPre.style.color = "#2d353c";
					btnNext.style.color = "#2d353c";
				}
			},
		},
	};
</script>
